import React, { useEffect, useRef, useState } from "react";
import {
  Alert,
  AlertTitle,
  Button,
  Link,
  Stack,
  Typography
} from "@mui/material";
import PaymentDataForm from "../PaymentCard";
import { IAppState } from "../../../../store";
import { connect } from "react-redux";
import {
  ICreditCard,
  PaymentMode,
  PaymentType
} from "../../../../actionReducers/Checkout";
import { ActionCreator, AnyAction, Dispatch } from "redux";
import { IProduct } from "../../../../graphql/types/products";
import calculatePrice from "../../../../helpers/priceCalculation";
import { GraphQLError } from "graphql";
import { toast } from "react-toastify";
import {
  IQueryCheckCoupon,
  QUERY_CHECK_COUPON
} from "../../../../graphql/queries/coupons";
import { withApollo, WithApolloClient } from "react-apollo";
import {
  ADD_COUPON,
  ICartAction,
  ICartState,
  SET_TOTAL
} from "../../../../actionReducers/Cart";
import { IPublicCoupon } from "../../../../graphql/types/coupons";
import {
  IQueryCalculateOrderTotalPrice,
  QUERY_CALCULATE_ORDER_TOTAL_PRICE
} from "../../../../graphql/queries/checkoutOrders";
import {
  IFastCheckoutAction,
  SET_PERSONAL_DATA_ALREADY_SAVED,
  SET_TYPE_PAYMENT_FAST_CHECKOUT
} from "../../../../actionReducers/FastCheckout";
import { REMOVE_COUPON_FROM_CART } from "../../../../actionReducers/Cart";
import PaymentMethodLabel from "../PaymentMethodLabel";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Cookies from "../../../../helpers/Cookies";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import OtherPayment from "../../../../components/Pages/Checkout/Payments/OtherPayment";
import PicpayPayment from "../../../../components/Pages/Checkout/Payments/PicpayPayment";
import Config from "../../../../config";
import { googleTag } from "../../../../helpers/analytics";
import { EXTERNAL_LEAD_ID_FAST_CHECKOUT } from "../../../../boot/constants";
import PaymentCardRecurrence from "../PaymentCardRecurrence";

interface IProps {
  total: number;
  cart: ICartState;
  creditCard: ICreditCard;
  product: IProduct;
  couponCheckout?: IPublicCoupon;
  currentPaymentType: PaymentType;
  setTypePayment: ActionCreator<IFastCheckoutAction>;
  addCoupon: ActionCreator<ICartAction>;
  setTotal: ActionCreator<ICartAction>;
  removeCoupon: ActionCreator<ICartAction>;
  paymentsTabAccessed: ActionCreator<IFastCheckoutAction>;
  paymentError: Boolean;
  paymentMode?: string;
  setPaymentDay: (data: string) => void;
}

interface ICalculateAmount {
  coupon?: IPublicCoupon;
  paymentType: PaymentType;
}

const PaymentStep = ({
  client,
  cart: { items },
  total,
  product: { prices, delivery },
  currentPaymentType,
  paymentMode,
  setPaymentDay,
  couponCheckout,
  setTypePayment,
  addCoupon,
  setTotal,
  paymentsTabAccessed,
  removeCoupon,
  paymentError
}: IProps & WithApolloClient<{}>) => {
  const initialFormRef = useRef(null);
  const ALLU_ORIGINS = ['admin', 'new-site', 'fast_checkout', 'social-campaign', 'email-campaign', 'upgrade'];
  const [paymentModeValue, setPaymentModeValue] = useState<string>('');

  useEffect(() => {
    initialFormRef.current?.scrollIntoView(false);
    paymentsTabAccessed(true);
    // calculateTotal({ paymentType: currentPaymentType, coupon });

    // const isPicpayPayment =
    //   items[0].paymentMode === PaymentMode.YEARLY &&
    //   origin?.toLowerCase() === "picpay";
    chosenTypePayment(PaymentType.CREDIT_CARD);
  }, []);

  const chosenTypePayment = async (type: PaymentType) => {
    if (type === PaymentType.PIX) {
      await addingDiscountBillet(type);
    }
    if (type === PaymentType.CREDIT_CARD) {
      calculateTotal({
        paymentType: PaymentType.CREDIT_CARD,
        coupon: couponCheckout || null
      });
      if (!couponCheckout) {
        removeCoupon();
      }
    }
    setTypePayment(
      type,
      calculatePrice(Number(prices.signatureLite), 365, 1, delivery)
    );
  };

  const queryCoupon = async (type: string) => {
    try {
      const data = await client.query<IQueryCheckCoupon>({
        query: QUERY_CHECK_COUPON,
        variables: {
          slug: type.toUpperCase(),
          billet: true
        }
      });

      if (data && !data.data.checkCoupon.flags.disable) {
        const coupon = data.data.checkCoupon;
        return coupon;
      }
    } catch (e) {
      e.graphQLErrors.map((error: GraphQLError) => toast.error(error.message));
      return;
    }
  };

  const addingDiscountBillet = async (type: PaymentType) => {
    const couponPaymentMethod = await queryCoupon(type);
    if (couponPaymentMethod) {
      if (
        !couponCheckout ||
        (couponPaymentMethod.amount > couponCheckout.amount &&
          couponPaymentMethod.type === couponCheckout.type)
      ) {
        addCoupon(couponPaymentMethod);
        await calculateTotal({
          coupon: couponPaymentMethod,
          paymentType: type
        });
      }
    }
  };

  const calculateTotal = async ({ coupon }: ICalculateAmount) => {
    const {
      data: { calculateOrderTotalPrice }
    } = await client.query<IQueryCalculateOrderTotalPrice>({
      query: QUERY_CALCULATE_ORDER_TOTAL_PRICE,
      variables: {
        items: items.map(i => ({
          product: i.product._id,
          quantity: i.quantity,
          paymentMode: i.paymentMode,
          endDate: i.endDate,
          startDate: i.startDate,
        })),
        couponSlug: (coupon && coupon.slug) || "",
        loyaltyPlan: items[0].loyaltyPlan,
      },
      fetchPolicy: "no-cache"
    });
    if (calculateOrderTotalPrice) {
      setTotal(calculateOrderTotalPrice);
      if (coupon) {
        addCoupon(coupon);
      }
    }
  };
  const origin = Cookies.get("origin_fast_checkout");
  const lead_id = Cookies.get(EXTERNAL_LEAD_ID_FAST_CHECKOUT) ?? '';

  useEffect(() => {
    googleTag("payment_method_stage", {
      send_to: "G-MFSWHTC64V",
      partner: origin,
      product: items[0]?.product?.slug,
      lead_id,
    });
    dataLayer.push({
      event: "payment_method_stage",
      product: items[0]?.product?.slug,
      origin
    })
  }, []);

  useEffect(() => {
    setPaymentModeValue(paymentMode)
  }, [])

  return (
    <Stack spacing={"24px"} alignItems={"center"} ref={initialFormRef}>
      {!currentPaymentType && (
        <Typography variant="h6" fontSize={21}>
          Escolha a sua forma de pagamento
        </Typography>
      )}
      {paymentError && (
        <Stack spacing={"24px"} sx={{ width: "100%" }}>
          <Alert variant="standard" color="warning" severity="error">
            <AlertTitle color="black">
              {<strong>O Número máximo de tentativas foi excedido.</strong>}
            </AlertTitle>
            {
              <Typography variant="body1" color="black">
                Quer ajuda para concluir sua assinatura? {<br />} Fale com um de
                nossos consultores!
              </Typography>
            }
            {
              <Link href="https://allugator.trb.ai/wa/wxpnaZ" target="_blank">
                <Button
                  variant="outlined"
                  sx={{
                    textTransform: "none",
                    color: "#000000",
                    borderColor: "#000000",
                    marginTop: "20px",
                    borderRadius: "50px",
                    ":hover": {
                      backgroundColor: "transparent",
                      borderColor: "#000000"
                    }
                  }}
                  endIcon={<ArrowForwardIcon />}
                  startIcon={
                    <WhatsAppIcon sx={{ color: "#000000" }} fontSize="small" />
                  }
                >
                  {<strong>Fale com a gente</strong>}
                </Button>
              </Link>
            }
          </Alert>
        </Stack>
      )}
      <Stack
        spacing={"24px"}
        // direction={!currentPaymentType || currentPaymentType === PaymentType.CREDIT_CARD ? 'column' : 'column-reverse'}
        sx={{ width: "100%" }}
      >
        <>
          {paymentMode !== 'recurrence' &&
            <Typography fontFamily={"Chillax"} variant="h5" fontWeight={500}>
              Escolha um método de pagamento
            </Typography>
          }
        </>

        <PaymentMethodLabel
          paymentType={PaymentType.CREDIT_CARD}
          onClick={() => chosenTypePayment(PaymentType.CREDIT_CARD)}
          paymentMode={paymentMode}
        >
          {currentPaymentType === PaymentType.CREDIT_CARD ? (
            paymentModeValue === "recurrence" ?
              <PaymentCardRecurrence
                setPaymentDay={setPaymentDay}
              />
              :
              <PaymentDataForm amount={total} />
          ) : null}
        </PaymentMethodLabel>

        {items[0].paymentMode === PaymentMode.YEARLY &&
          origin?.toLowerCase() === "picpay" && (
            <PaymentMethodLabel
              paymentType={PaymentType.PICPAY}
              onClick={() => chosenTypePayment(PaymentType.PICPAY)}
            >
              {currentPaymentType === PaymentType.PICPAY ? (
                <PicpayPayment />
              ) : null}
            </PaymentMethodLabel>
          )}

        {(items[0].paymentMode === PaymentMode.YEARLY && ALLU_ORIGINS.includes(origin?.toLowerCase())) && (
          <PaymentMethodLabel
            paymentType={PaymentType.PIX}
            onClick={() => chosenTypePayment(PaymentType.PIX)}
          >
            {currentPaymentType === PaymentType.PIX ? <OtherPayment /> : null}
          </PaymentMethodLabel>
        )}
        <Typography>
          Ao finalizar o pagamento, você estará de acordo com os
          <Link href={Config.termosDeUsoUrl} target="_blank" rel="noopener">
            Termos de Uso
          </Link>{" "}
          e
          <Link
            href={Config.avisoProtecaoDadosUrl}
            target="_blank"
            rel="noopener"
          >
            {" "}
            Aviso de Proteção de Dados
          </Link>{" "}
          da Allugator relativos à assinatura.
        </Typography>
      </Stack>
    </Stack>
  );
};

export default connect(
  (state: IAppState) => ({
    currentPaymentType: state.fastCheckout.paymentType,
    deliveredAddress: state.fastCheckout.deliveredAddress,
    paymentType: state.fastCheckout.paymentType,
    creditCard: state.fastCheckout.creditCard,
    total: state.cart.total,
    cart: state.cart,
    couponCheckout: state.fastCheckout.coupon,
    origin: state.fastCheckout.origin
  }),
  (dispatch: Dispatch<AnyAction>) => ({
    setTotal: (total: number) => dispatch(SET_TOTAL(total)),
    addCoupon: (coupon: IPublicCoupon) => dispatch(ADD_COUPON(coupon)),
    removeCoupon: () => dispatch(REMOVE_COUPON_FROM_CART()),
    setTypePayment: (type: PaymentType, total: number) =>
      dispatch(SET_TYPE_PAYMENT_FAST_CHECKOUT(type, total)),
    paymentsTabAccessed: (value: boolean) =>
      dispatch(SET_PERSONAL_DATA_ALREADY_SAVED(value))
  })
)(withApollo(PaymentStep));
