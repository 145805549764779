import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography
} from '@mui/material';
import { Formik } from 'formik';
import React from 'react';
import Address from '../../../components/Address/form';
import { ICheckoutOrder } from "../../../graphql/types/checkoutOrders";
import { IUserAddress } from '../../../graphql/types/users';
import { validatorAddressSchema } from '../../../validators/validatorAddress';
  
  const initialValues: IUserAddress = {
    city: '',
    code: '',
    street: '',
    complement: '',
    neighborhood: '',
    number: '',
    state: '',
  };
  
  interface IProps {
    billingAddress: IUserAddress;
    setBillingAddressData: (data: IUserAddress) => void;
    differentBillingAddress: boolean;
    setDifferentBillingAddress: (data: boolean) => void
    order?: ICheckoutOrder;
  };
  
  
  const BillingAddress = ({ billingAddress, setBillingAddressData, differentBillingAddress, setDifferentBillingAddress, order }: IProps) => {
  
    const handleAddressChange = (event: any) => {
      const { value } = event.target;
      setDifferentBillingAddress(value === 'true');
    }

    const initialFormValues = (): IUserAddress => {
      if (billingAddress) {
        return {
          city: billingAddress.city || '',
          code: billingAddress.code || '',
          street: billingAddress.street || '',
          complement: billingAddress.complement || '',
          neighborhood: billingAddress.neighborhood || '',
          number: billingAddress.number || '',
          state: billingAddress.state || '',
        };
      }
      return initialValues;
    };
  
    return (
      <Stack spacing={'20px'} sx={{ marginTop: '24px' }}>
        <Typography>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="false"
            onChange={handleAddressChange}
          >
            <FormControlLabel value="false" control={<Radio />} label="Meu endereço residencial é o mesmo do endereço de entrega" />
            <FormControlLabel value="true" control={<Radio />} label="Meu endereço residencial é diferente" />
          </RadioGroup>
        </Typography>
        {differentBillingAddress && !order?.company && (
          <Formik
            initialValues={initialFormValues()}
            onSubmit={() => { }}
            validationSchema={validatorAddressSchema}
            enableReinitialize={true}
          >
            {newFormikProps => {
              setBillingAddressData(newFormikProps.values);
              return <Address {...newFormikProps} scrollActive={true} />;
            }
            }
          </Formik>
        )}
      </Stack>
    );
  };

  export default BillingAddress