import { Button, Divider, Grid, Link, Stack, Typography } from '@mui/material';
import axios from 'axios';
import { parse } from 'query-string';
import React, { useEffect, useState } from 'react';
import { withApollo, WithApolloClient } from 'react-apollo';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ActionCreator, AnyAction, Dispatch } from 'redux';
import { PaymentType } from '../../actionReducers/Checkout';
import { IGeneralAction, SET_LOADING } from '../../actionReducers/General';
import { CLEAN_CHECKOUT_RECURRENCE, ICheckoutRecurrenceAction, IPayment, SET_ADDRESS_ORDER, SET_AMOUNT_ENTRY, SET_AMOUNT_MONTHLY, SET_LINK_BOLETO, SET_ORDER } from '../../actionReducers/Recurrence';
import ResumeCheckout from '../../components/Checkout/ResumeCheckout';
import ProductFooter from '../../components/Footer';
import Header from '../../components/Pages/Checkout/Header';
import Config from '../../config';
import { IQueryOrderByShortId, QUERY_ORDER_BY_SHORTID } from '../../graphql/queries/checkoutOrders';
import { CheckoutOrderStatus, ICheckoutOrder, ICheckoutOrderProduct } from '../../graphql/types/checkoutOrders';
import { IUserAddress } from '../../graphql/types/users';
import CNPJValidation from "../../helpers/CNPJValidation";
import CPFValidation from '../../helpers/CPFValidation';
import { IAppState } from '../../store';
import BillingAddress from './BillingAddress';
import DeliveredAddressForm from './DeliveredAddress';
import PaymentStep from './Payment';
import PersonalData, { IFormikPersonalDataRecurrenceFields } from './PersonalData';
import { Background, Modal } from './styled';
interface IProps {
  order: ICheckoutOrder;
  loading: boolean;
  singleCard: boolean;
  paymentEntry: IPayment;
  paymentMonthly: IPayment;
  setLoading: ActionCreator<IGeneralAction>;
  setAmountEntry: ActionCreator<ICheckoutRecurrenceAction>;
  setAmountMonthly: ActionCreator<ICheckoutRecurrenceAction>;
  setOrder: ActionCreator<ICheckoutRecurrenceAction>;
  setAddressOrder: ActionCreator<ICheckoutRecurrenceAction>;
  clearCheckout: ActionCreator<ICheckoutRecurrenceAction>;
  setLinkBoleto: ActionCreator<ICheckoutRecurrenceAction>;
}

type Props = RouteComponentProps<{}> & WithApolloClient<{}> & IProps;

const ReccurencePayment: React.FC<Props> = ({
  loading,
  client,
  history,
  singleCard,
  location,
  order,
  paymentEntry,
  paymentMonthly,
  setLoading,
  setAmountEntry,
  setAmountMonthly,
  setOrder,
  setAddressOrder,
  clearCheckout,
  setLinkBoleto
}: Props) => {

  const [orderProducts, setOrderProducts] = useState<ICheckoutOrderProduct[]>([]);
  const [blocked, setBlocked] = useState<boolean>(false);
  const [acceptedTerms, setAcceptedTerms] = useState<boolean>(false);
  const [deliveredAddress, setDeliveredAddress] = useState<IUserAddress>();
  const [billingAddress, setBillingAddress] = useState<IUserAddress>();
  const [personalData, setPersonalData] = useState<IFormikPersonalDataRecurrenceFields>({ document: "", email: "", phone: "" });
  const [paymentDay, setPaymentDay] = useState<number>(null);
  const [differentBillingAddress, setDifferentBillingAddress] = useState<boolean>(false)
  const params = parse(location.search);

  useEffect(() => {
    clearCheckout();
    setLoading(true);

    const query = parse(location.search);

    if (!query || !query.pedido) {
      history.push('/');
      setLoading(false);
      return;
    }

    async function searchOrder() {
      try {
        const { data: { searchOrderById } } = await client.query<IQueryOrderByShortId>({
          query: QUERY_ORDER_BY_SHORTID,
          variables: { shortId: query.pedido },
        });

        setOrder(searchOrderById);

        if (searchOrderById.status === CheckoutOrderStatus.APPROVED) {
          setBlocked(true);
        }

        if (searchOrderById && searchOrderById.deliveryAddress) {
          setDeliveredAddress(searchOrderById.deliveryAddress);
        }

        if (searchOrderById && searchOrderById.subscriptionPayment && searchOrderById.subscriptionPayment.entryFeeValue) {
          try {
            setAmountEntry(
              (searchOrderById.subscriptionPayment.entryFeeValue || 0) +
              (searchOrderById.subscriptionPayment.monthValue || 0)
            );
          } catch (err) {
            setLoading(false);
            toast.error('Pedido inválido.');
            history.push('/');
            return;
          }
        }

        if (searchOrderById && searchOrderById.subscriptionPayment && searchOrderById.subscriptionPayment.monthValue) {
          setAmountMonthly(searchOrderById.subscriptionPayment.monthValue);

          setOrderProducts(searchOrderById.checkoutOrderProducts);
          setLoading(false);
          return;
        }
        setLoading(false);
        history.push('/');
        return;
      }
      catch (err) {
        setLoading(false);
        toast.error('Pedido não encontrado.');
        history.push('/');
      }
    }
    searchOrder();
  }, []);

  useEffect(() => {
    setAddressOrder(deliveredAddress);
  }, [deliveredAddress]);

  const generateTokenCard = async (card: any) => {
    try {
      const { data } = await axios.post(`${Config.PagarmeV5URL}/tokens?appId=${Config.PagarmeV5Key}`, {
        type: "card",
        card,
      });
      return data.id;
    } catch (err) {
      setLoading(false);
      return;
    }
  };

  const formatCard = (creditCard: any, billing_address: any) => {
    try {
      const card = {
        number: creditCard.number.replace(/\s/g, ''),
        holder_name: creditCard.holderName,
        exp_month: creditCard.expirationDate.substring(0, 2),
        exp_year: creditCard.expirationDate.substring(2),
        cvv: creditCard.cvv,
        billing_address,
      };
      return card;
    } catch (error) {
      return false
    }
  };

  const submit = async () => {
    if (!paymentDay) {
      return toast.error('Favor inserir o dia de cobrança (a partir da segunda parcela)');
    }

    const query = parse(location.search);

    if (Object.keys(query).length === 0) {
      return toast.error('Favor inserir o código do pedido na url');
    }

    let cleanDocument = personalData.document.replace(/\D+/g, '');

    if (order.company) {
      if (!CNPJValidation(order.company.cnpj)) {
        return toast.error('CNPJ inválido.');
      }
      cleanDocument = order.company.cnpj.replace(/\D+/g, '');
    } else if (!CPFValidation(cleanDocument)) {
      return toast.error('CPF inválido.');
    }

    setLoading(true);
    try {
      let entry = {};

      const billing_address = {
        zip_code: deliveredAddress.code,
        city: deliveredAddress.city,
        state: deliveredAddress.state,
        country: 'BR',
        line_1: `${deliveredAddress.number}, ${deliveredAddress.street}, ${deliveredAddress.neighborhood}`,
        line_2: deliveredAddress.complement || ' '
      };

      if (paymentEntry.amount && paymentEntry.amount > 0) {
        if (paymentEntry.type === PaymentType.BOLETO) {
          entry = {
            type: PaymentType.BOLETO
          };
        } else {
          const { creditCard } = paymentEntry;
          const card = formatCard(creditCard, billing_address);
          if (!card) {
            toast.error('Inserir dados do cartão manualmente');
            return;
          }
          const tokenEntry = await generateTokenCard(card);
          if (!tokenEntry) {
            toast.error('Cartão de entrada inválido, por favor verifique seus dados.');
            return;
          }

          entry = {
            type: PaymentType.CREDIT_CARD,
            card_token: tokenEntry
          };
        }
      } else {
        entry = null;
      }

      const addresses = {
        deliveryAddress: {
          code: deliveredAddress.code ? deliveredAddress.code.replace(/\D+/g, "") : "",
          street: deliveredAddress.street,
          number: deliveredAddress.number,
          complement: deliveredAddress.complement ? deliveredAddress.complement : `${deliveredAddress.street} - ${deliveredAddress.neighborhood}, Nº ${deliveredAddress.number}`,
          neighborhood: deliveredAddress.neighborhood,
          city: deliveredAddress.city,
          state: deliveredAddress.state,
          identifier: `${deliveredAddress.street} - ${deliveredAddress.number}`,
        },
        ...(differentBillingAddress && {
          billingAddress: {
            code: billingAddress.code ? billingAddress.code.replace(/\D+/g, "") : "",
            street: billingAddress.street,
            number: billingAddress.number,
            complement: billingAddress.complement
              ? billingAddress.complement
              : `${billingAddress.street} - ${billingAddress.neighborhood}, Nº ${billingAddress.number}`,
            neighborhood: billingAddress.neighborhood,
            city: billingAddress.city,
            state: billingAddress.state,
            identifier: `${billingAddress.street} - ${billingAddress.number}`,
          },
        }),
      }

      const card = singleCard && paymentEntry.type === PaymentType.CREDIT_CARD
        ? formatCard(paymentEntry.creditCard, billing_address)
        : formatCard(paymentMonthly.creditCard, billing_address)
      if (!card) {
        toast.error('Inserir dados do cartão manualmente');
        return;
      }

      const tokenCardMonthly = await generateTokenCard(card)
      if (!tokenCardMonthly) {
        toast.error('Cartão de assinatura inválido, por favor verifique seus dados.');
        return;
      }

      const recurrencePayment = await axios.post(`${Config.YacareURL}/pagamentoRecorrente`, {
        card_token: tokenCardMonthly,
        pedido: query.pedido,
        document: cleanDocument,
        phone: personalData.phone,
        email: personalData.email,
        addresses,
        entry,
        singleCard,
        paymentDay
      });
      setLoading(false);
      if (recurrencePayment.data) {
        const responseOrder = recurrencePayment.data.data;
        if (paymentEntry.type === PaymentType.BOLETO
          && responseOrder.charges && responseOrder.charges.length
          && responseOrder.charges[0].last_transaction.transaction_type === PaymentType.BOLETO
          && responseOrder.charges[0].last_transaction.url) {
          setLinkBoleto(responseOrder.charges[0].last_transaction.url);
        }
        toast.success(recurrencePayment.data.message || 'Assinatura realizada com sucesso.');
        return history.push('/obrigado-recorrencia');
      }
    } catch (error) {
      setLoading(false);
      toast.error('Erro ao criar assinatura, por favor verifique seus dados!');
    }
  };

  return (
    <React.Fragment>
      {
        blocked && (
          <Modal>
            Para alterar dados de cobrança clique abaixo e selecione seu pedido:
            <Button variant='contained' onClick={() => history.push('/pedidos/agendados')}>Meus pedidos</Button>
          </Modal>
        )
      }
      <Background modalOpen={blocked}>
        <React.Fragment>
          <Header />
          <Grid
            container
            maxWidth={1440}
            margin={'0px auto'}
            sx={{
              padding: {
                xs: '20px 18px 23px 18px',
                sm: '20px 36px 46px 36px',
                md: '40px 72px 92px 72px',
              },
            }}
          >
            <Grid container columnSpacing={'32px'}>
              {loading ? null : (
                <Grid item xs={12} sm={12} md={4}>
                  <Stack
                    sx={{
                      boxShadow: '0px 5px 8px rgba(120, 111, 111, 0.15)',
                      padding: '24px ',
                    }}
                  >
                      <PersonalData personalData={personalData} setPersonalData={setPersonalData} shortId={params?.pedido} />
                      <DeliveredAddressForm deliveredAddress={deliveredAddress} setDeliveredAddress={setDeliveredAddress} order={order} />
                      {!order?.company && (
                        <BillingAddress
                          billingAddress={billingAddress}
                          setBillingAddressData={setBillingAddress}
                          differentBillingAddress={differentBillingAddress}
                          setDifferentBillingAddress={setDifferentBillingAddress}
                          order={order}
                        />
                      )}
                      <Divider sx={{ paddingTop: '24px' }} />
                      <Typography fontSize={14} marginTop={3}>Problemas com a confirmação? Fale com um especialista <Link href="https://allugator.trb.ai/wa/wxpnaZ" target="_blank">aqui</Link></Typography>
                  </Stack>
                </Grid>
              )}
              <Grid item xs={12} sm={12} md={4}>
                {
                  orderProducts && orderProducts.length
                    ? <PaymentStep dataPersonal={personalData} deliveredAddress={deliveredAddress} paymentDay={paymentDay} handlePaymentDay={setPaymentDay} />
                    : null
                }
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                {
                  orderProducts && orderProducts.length
                    ? <ResumeCheckout
                      acceptedTerms={acceptedTerms}
                      setAcceptedTerms={setAcceptedTerms}
                      submit={submit}
                    />
                    : null
                }
              </Grid>
            </Grid>
          </Grid>
          <ProductFooter />
        </React.Fragment>
      </Background>
    </React.Fragment>

  );
};

export default connect((state: IAppState) => ({
  order: state.checkoutRecurrence.order,
  loading: state.general.loading,
  paymentEntry: state.checkoutRecurrence.paymentEntry,
  paymentMonthly: state.checkoutRecurrence.paymentRecurrence,
  singleCard: state.checkoutRecurrence.singleCard
}), ((dispatch: Dispatch<AnyAction>) => ({
  setLoading: (loadingState: boolean) => dispatch(SET_LOADING(loadingState)),
  setAmountEntry: (amount: number) => dispatch(SET_AMOUNT_ENTRY(amount)),
  setAmountMonthly: (amount: number) => dispatch(SET_AMOUNT_MONTHLY(amount)),
  setOrder: (order: ICheckoutOrder) => dispatch(SET_ORDER(order)),
  setAddressOrder: (address: IUserAddress) => dispatch(SET_ADDRESS_ORDER(address)),
  setLinkBoleto: (link: string) => dispatch(SET_LINK_BOLETO(link)),
  clearCheckout: () => dispatch(CLEAN_CHECKOUT_RECURRENCE())
})))(withApollo(ReccurencePayment));

