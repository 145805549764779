import * as moment from "moment-timezone";
import { Action } from "redux";
import { ALLUGATOR_AUTH_TOKEN, ALLUGATOR_CART_TOKEN, PAYMENTMODE_FAST_CHECKOUT } from "../boot/constants";
import allowedDays from "../config/allowedDays";
import { Client } from "../config/graphql";
import { MUTATION_SAVE_CART } from "../graphql/mutations/users";
import { IPublicCoupon } from "../graphql/types/coupons";
import { IProduct } from "../graphql/types/products";
import Cookies from "../helpers/Cookies";
import calculateSubtotal, { LoyaltyPlan } from "../helpers/calculateSubtotal";
import { CartActionTypes } from "./actionTypes";
import { PaymentMode } from "./Checkout";

export interface ICartAction extends Action<CartActionTypes> {
  [extraProp: string]: any;
}

/**
 * Reducer
 */

export interface ICartItem {
  startDate: moment.Moment;
  endDate: moment.Moment;
  quantity: number;
  duration?: number;
  product?: IProduct;
  plan?: string;
  proposalId?: string;
  paymentMode?: string;
  loyaltyPlan?: LoyaltyPlan;
}

export interface ICartState {
  items: ICartItem[];
  coupon?: IPublicCoupon;
  couponBillet?: IPublicCoupon;
  selectedAddress?: number;
  goPhone?: boolean;
  subtotal: number;
  total: number;
}

const saveCart = (variables: any) =>
  Client(Cookies).mutate({
    mutation: MUTATION_SAVE_CART,
    variables
  });

const updateLocalStorage = (state: ICartState) => {
  localStorage.setItem(ALLUGATOR_CART_TOKEN, JSON.stringify(state));
  const logged = !!Cookies.get(ALLUGATOR_AUTH_TOKEN);
  if (state.items.length > 0 && logged) {
    saveCart({
      items: state.items,
      selectedAddress: state.selectedAddress,
      coupon: (state.coupon && state.coupon.slug) || undefined,
      goPhone: state.goPhone || undefined
    });
  } else {
    if (logged) {
      saveCart({});
    }
  }
};

const initialState: ICartState = {
  items: [],
  subtotal: 0,
  total: 0
};

export const cartReducer = (
  state: ICartState = initialState,
  action: ICartAction
): ICartState => {
  switch (action.type) {
    case CartActionTypes.LOAD_DATA_FROM_LOCAL_STORAGE: {
      return { ...state, ...action.payload };
    }

    case CartActionTypes.ADD_TO_CART: {
      const { item, preowned } = action;
      const items = [item];

      const subtotal = calculateSubtotal(items, preowned, item.loyaltyPlan);

      const nextState: ICartState = {
        ...state,
        items,
        subtotal,
        total: subtotal
      };
      updateLocalStorage(nextState);
      return nextState;
    }

    case CartActionTypes.CHANGE_SUBSCRIPTION_PLAN: {
      let oldCart = JSON.parse(localStorage.getItem(ALLUGATOR_CART_TOKEN));
      const { paymentMode } = action;
      Cookies.set(PAYMENTMODE_FAST_CHECKOUT, paymentMode, { path: '/' });

      oldCart.items.forEach(item => {
        item.paymentMode = paymentMode;
      });

      const subtotal = calculateSubtotal(oldCart.items);
      
      const nextState = {
        ...state,
        items: oldCart.items,
        subtotal
      };
      updateLocalStorage(nextState);
      return nextState;
    }

    case CartActionTypes.REMOVE_FROM_CART: {
      const items = state.items.filter(
        item => item.product._id.toString() !== action.id
      );
      const subtotal = calculateSubtotal(items);

      const nextState: ICartState = { ...state, items, subtotal };
      updateLocalStorage(nextState);
      return nextState;
    }

    case CartActionTypes.REMOVE_COUPON_FROM_CART: {
      const { id } = action;

      const items = state.items.filter(
        item => item.product._id.toString() !== id
      );

      const nextState: ICartState = { ...state, items, coupon: undefined };
      updateLocalStorage(nextState);
      return nextState;
    }

    case CartActionTypes.REMOVE_GOPHONE: {
      const nextState: ICartState = { ...state, goPhone: false };
      updateLocalStorage(nextState);
      return nextState;
    }

    case CartActionTypes.CLEAR_CART: {
      const nextState: ICartState = initialState;
      updateLocalStorage(nextState);
      return nextState;
    }

    case CartActionTypes.SELECT_ADDRESS: {
      const { addressIndex } = action;

      const nextState: ICartState = { ...state, selectedAddress: addressIndex };
      updateLocalStorage(nextState);
      return nextState;
    }

    case CartActionTypes.REMOVE_SELECTED_ADDRESS: {
      const nextState: ICartState = { ...state, selectedAddress: null };
      updateLocalStorage(nextState);
      return nextState;
    }

    case CartActionTypes.UPDATED_CARD: {
      const nextState: ICartState = { ...state };
      updateLocalStorage(nextState);
      return nextState;
    }

    case CartActionTypes.ADD_COUPON: {
      const { coupon } = action;
      const nextState: ICartState = { ...state, coupon };
      updateLocalStorage(nextState);
      return nextState;
    }

    case CartActionTypes.ADD_GOPHONE: {
      const nextState: ICartState = { ...state, goPhone: true };
      updateLocalStorage(nextState);
      return nextState;
    }

    case CartActionTypes.SET_TOTAL: {
      const { total } = action.payload;
      const nextState: ICartState = {
        ...state,
        total
      };
      updateLocalStorage(nextState);
      return nextState;
    }

    default:
      return state;
  }
};

/**
 * Actions
 */

export const ADD_COUPON = (coupon: IPublicCoupon): ICartAction => {
  return {
    type: CartActionTypes.ADD_COUPON,
    coupon
  };
};

export const ADD_GOPHONE = (): ICartAction => {
  return {
    type: CartActionTypes.ADD_GOPHONE
  };
};

export const REMOVE_SELECTED_ADDRESS = (): ICartAction => ({
  type: CartActionTypes.REMOVE_SELECTED_ADDRESS
});

export const UPDATED_CARD = (): ICartAction => ({
  type: CartActionTypes.UPDATED_CARD
});

export const ADD_TO_CART = (item:ICartItem, preowned: boolean = false): ICartAction => {
  return {
    type: CartActionTypes.ADD_TO_CART,
    item,
    preowned
  };
};

export const CHANGE_SUBSCRIPTION_PLAN = (paymentMode: PaymentMode): ICartAction => {
  return {
    type: CartActionTypes.CHANGE_SUBSCRIPTION_PLAN,
    paymentMode
  };
};

export const REMOVE_FROM_CART = (id: string): ICartAction => ({
  type: CartActionTypes.REMOVE_FROM_CART,
  id
});

export const REMOVE_COUPON_FROM_CART = (): ICartAction => ({
  type: CartActionTypes.REMOVE_COUPON_FROM_CART
});

export const REMOVE_GOPHONE = (): ICartAction => ({
  type: CartActionTypes.REMOVE_GOPHONE
});

export const CLEAR_CART = (): ICartAction => ({
  type: CartActionTypes.CLEAR_CART
});

export const SELECT_ADDRESS = (addressIndex: number): ICartAction => ({
  type: CartActionTypes.SELECT_ADDRESS,
  addressIndex
});

export const LOAD_DATA_FROM_LOCAL_STORAGE = (): ICartAction => {
  let oldCart: any;
  const items = [];

  try {
    oldCart = JSON.parse(localStorage.getItem(ALLUGATOR_CART_TOKEN));

    if (oldCart.items) {
      if (oldCart.items.some(product => !product.product.prices)) {
        return CLEAR_CART();
      }

      for (const item of oldCart.items) {
        const momentStartDate = moment.tz(item.startDate, "UTC");
        const momentEndDate = moment.tz(item.endDate, "UTC");
        const diff = Math.round(
          momentEndDate.diff(momentStartDate, "days", true)
        );

        if (!allowedDays.find(day => day.days === diff)) {
          return CLEAR_CART();
        }

        items.push({
          ...item,
          startDate: moment.tz(item.startDate, "UTC"),
          endDate: moment.tz(item.endDate, "UTC")
        });
      }
    }

    if (oldCart.selectedAddress === null) {
      oldCart.selectedAddress = undefined;
    }
  } catch (e) {
    oldCart = {};
  }

  const numberSelectedAddress = Number(oldCart.selectedAddress);

  return {
    type: CartActionTypes.LOAD_DATA_FROM_LOCAL_STORAGE,
    payload: {
      coupon: oldCart.coupon || undefined,
      goPhone: oldCart.goPhone,
      items,
      selectedAddress: Number.isInteger(numberSelectedAddress)
        ? numberSelectedAddress
        : undefined,
      subtotal: oldCart.subtotal,
      total: oldCart.total
    }
  };
};

export const SET_TOTAL = (total: number): ICartAction => ({
  type: CartActionTypes.SET_TOTAL,
  payload: { total }
});
