import * as winston from 'winston';

const { combine, colorize, timestamp, printf } = winston.format;

const Logger = winston.createLogger({
  format: combine(
    colorize(),
    timestamp(),
    printf((info) => `${info.timestamp} :: ${info.level}: ${info.message}`),
  ),
  transports: [
    new winston.transports.Console({
      handleExceptions: true,
    }),
  ],
});

export default Logger;
