import { Box, CircularProgress, Stack } from '@mui/material';
import { Formik } from 'formik';
import React from 'react';
import { IAppState } from '../../../../store';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import PersonalDataForm from '../PersonalDataForm';
import { validatorPersonalDataFastCheckout } from '../../../../validators/validatorPersonalDataFastCheckout';
import { IPersonalDataFastCheckout } from '../../../../actionReducers/FastCheckout';
import moment from 'moment';
import { IInvalidDocument } from '../..';
import { ICartState } from '../../../../actionReducers/Cart';

export interface IFormikPersonalDataFastCheckout {
  email: string;
  name: string;
  confirmEmail?: string;
  document?: string;
  phone: string;
  birthdate?: string;
  nestleCheck?: boolean;
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_term?: string;
  utm_content?: string;
}

interface IProps {
  loading?: boolean;
  personalData?: IPersonalDataFastCheckout;
  personalDataAlreadySaved?: boolean;
  invalidDocument: IInvalidDocument;
  setUserData: (data: IFormikPersonalDataFastCheckout) => void;
  cart: ICartState | any;
  valueForDataLayer: number;
}

const initialValues: IFormikPersonalDataFastCheckout = {
  name: '',
  email: '',
  phone: '',
  document: '',
  birthdate: '',
};

const PersonalDataFastCheckout = ({
  loading,
  personalData,
  personalDataAlreadySaved,
  invalidDocument,
  setUserData,
  cart,
  valueForDataLayer
}: IProps) => {
  const formatName = (firstName: string, lastName: string) => {
    if (firstName) return `${firstName} ${lastName}`;
    return "";
  };

  const handleInitialValues = (
    personalData: IPersonalDataFastCheckout
  ): IFormikPersonalDataFastCheckout => {
    if (personalData) {
      let initialData: IFormikPersonalDataFastCheckout = {
        name: formatName(personalData.firstName, personalData.lastName),
        email: personalData.email || "",
        phone: personalData.phone || "",
        document: personalData.document || "",
        birthdate: personalData.birthdate
          ? moment(personalData.birthdate).format("YYYY-MM-DD")
          : null,
        nestleCheck: personalData.nestleCheck || false
      };

      if (personalDataAlreadySaved) {
        initialData = { ...initialData, confirmEmail: personalData.email };
      }
      return initialData;
    }
    return initialValues;
  };

  return (
    <Box>
      <Formik
        initialValues={handleInitialValues(personalData)}
        onSubmit={() => { }}
        validationSchema={validatorPersonalDataFastCheckout}
        enableReinitialize={true}
        isInitialValid
      >
        {formikProps => {
          setUserData(formikProps.values);
          return (
            <Stack spacing={"24px"}>
              {loading ? (
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center"
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                <PersonalDataForm
                  valueForDataLayer={valueForDataLayer}
                  cart={cart}
                  {...formikProps}
                  invalidDocument={invalidDocument}
                />
              )}
            </Stack>
          );
        }}
      </Formik>
    </Box>
  );
};

export default connect(
  (state: IAppState) => ({
    loading: state.general.loading,
    personalData: state.fastCheckout.personalData,
    personalDataAlreadySaved: state.fastCheckout.personalDataAlreadySaved
  }), null
)(withApollo(PersonalDataFastCheckout));