import moment from 'moment';
import * as yup from 'yup';
import { validatorEmail } from './validatorEmail';

export const validatorPersonalDataFastCheckout = validatorEmail.concat(yup.object().shape({
    name: yup
        .string()
        .nullable(true)
        .required('Necessário informar nome completo')
        .min(7, 'Por favor informe seu nome completo')
        .test('Test complet name', 'Nome inválido, por favor informe nome completo', (name) => {
            if(name){
                const names = name
                .trim()
                .replace(/[0-9.]+/g, '')
                .split(' ');
                names.shift();
                const lastName = names.join(' ').trim();
                if (!lastName) return false;
                return true;
            } return false;
        }),
    phone: yup
        .string()
        .nullable(true)
        .required('Necessário informar número de telefone')
        .min(11, 'Número de telefone inválido'),
    document: yup
        .string()
        .nullable(true)
        .required('Necessário informar número de CPF')
        .min(11, 'Por favor informe seu CPF completo'),
    birthdate: yup
        .string()
        .nullable(true)
        .required('Necessário informar data de nascimento')
        .test('birthday', 'Necessário ter pelo menos 18 anos', async (value) => moment(value).isBefore(moment().subtract('y', 18))
        ),
}))