import Axios from "axios";
import Config from "../../config";

export const generateTokenCard = async (card: any) => {
  try {
    const { data } = await Axios.post(`${Config.PagarmeV5URL}/tokens?appId=${Config.PagarmeV5Key}`, {
      type: "card",
      card,
    });
    return data.id;
  } catch (err) {
    return false;
  }
};

export const generateMalgaTokenCard = async (card: any) => {
  try {
    const month = card.card_expiration_date.slice(0, 2);
    const year = card.card_expiration_date.slice(2, 4);
    const currentYear = new Date().getFullYear().toString().slice(0, 2);

    const { data: { tokenId } } = await Axios.post(`${Config.MalgaURL}/v1/tokens`,
      {
        cardNumber: card.card_number.replace(/ /g, ""),
        cardCvv: card.card_cvv.replace(/ /g, ""),
        cardExpirationDate: `${month}/${currentYear}${year}`,
        cardHolderName: card.card_holder_name
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'X-Client-Id':`${Config.MalgaClientId}`,
          'X-Api-Key': `${Config.MalgaPublicKey}`,
        }
      }
    );

    return tokenId
  } catch (error) {
    console.error(error)
    throw new Error('Erro ao gerar token de cartão de crédito')
  }
}
