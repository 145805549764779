export default {
  Env: process.env.RAZZLE_ENV || process.env.NODE_ENV || '',
  FacebookAppId: process.env.RAZZLE_FACEBOOK_APP_ID || '',
  GoogleAppId: process.env.RAZZLE_GOOGLE_APP_ID || '',
  YacareGraphQL: process.env.RAZZLE_YACARE_GRAPHQL || '',
  YacareURL: process.env.RAZZLE_YACARE_URL || '',
  RAZZLE_FAST_CHECKOUT_API: process.env.RAZZLE_FAST_CHECKOUT_API || '',
  MercadoPagoPublicKey: process.env.RAZZLE_MERCADOPAGO_PUBLIC_KEY || '',
  GmapsKey: process.env.RAZZLE_GMAPS_KEY || '',
  PagarmeV5URL: process.env.RAZZLE_PAGARME_V5_PUBLIC_URL || '',
  PagarmeV5Key: process.env.RAZZLE_PAGARME_V5_PUBLIC_KEY || '',
  PagarmeV3Key: process.env.RAZZLE_PAGARME_V3_PUBLIC_KEY || '',
  UserUrlApi: process.env.RAZZLE_USER_API_URL || '',
  TimeOutGetStatusIntregationDoc: process.env.RAZZLE_TIMEOUT_GET_STATUS_INTEGRATION_DOC || 7000,
  LimitSentsToMati: Number(process.env.RAZZLE_LIMIT_SENTS_MATI || '3'),
  ServicesApi: process.env.RAZZLE_SERVICES_API || '',
  ViaCepService: process.env.RAZZLE_VIA_CEP_API || '',
  LimitDisapprovedOrder: process.env.RAZZLE_LIMIT_DISAPPROVED_ORDER || 90,
  GoogleRecaptchaSiteKey: process.env.RAZZLE_GOOGLE_RECAPTCHA_SITE_KEY || '',
  termosDeUsoUrl: process.env.RAZZLE_TERMOS_USO || '',
  avisoProtecaoDadosUrl: process.env.RAZZLE_AVISO_PROTECAO_DADOS || '',
  showBannerCampaign: process.env.RAZZLE_SHOW_BANNER_CAMPAIGN || '',
  MalgaURL: process.env.RAZZLE_MALGA_PUBLIC_URL,
  MalgaClientId: process.env.RAZZLE_PUBLIC_MALGA_CLIENT_ID,
  MalgaPublicKey: process.env.RAZZLE_PUBLIC_MALGA_PUBLIC_KEY,
  siteBffUrl: process.env.RAZZLE_CONSUMERS_CONSUMERS_SITE_BFF || ''
};
