import { Action } from 'redux';
import { IPublicCoupon } from '../graphql/types/coupons';
import { IProduct } from '../graphql/types/products';
import { IPersonalData, IPublicUser, IUserAddress } from '../graphql/types/users';
import { CheckoutActionTypes } from "./actionTypes";

export interface ICheckoutAction extends Action<CheckoutActionTypes> {
    [extraProp: string]: any;
}

export interface IDynamicKeyCheckout {
    [key: string]: string | number;
}

export interface ICreditCard {
    number: string;
    holderName: string;
    expirationDate: string;
    cvv: string;
    installments?: number;
    errors?: boolean;
    cardInfo?: any;
}

export enum TabsCheckoutKey {
    PERSONAL_DATA = 'personal_data',
    PAYMENT = 'payment',
    SUMMARY = 'summary',
    ADDRESS = 'address'
}

export enum TabsCheckoutValues {
    PERSONAL_DATA = 'MEUS DADOS',
    PAYMENT = 'PAGAMENTO',
    SUMMARY = 'RESUMO',
    ADDRESS = 'ENDEREÇO'
}

export enum PaymentType {
    CREDIT_CARD = 'credit_card',
    BOLETO = 'boleto',
    PIX = 'pix',
    PICPAY = 'picpay',
}

export enum PaymentMode {
    YEARLY = 'yearly',
    SEMESTER = 'semester',
    QUARTERLY = 'quarterly',
    RECURRENCE = 'recurrence'
}

export interface IFinishedCheckout {
    product?: IProduct;
    total?: number;
    linkPayment?: string;
    blockCheckout?: boolean;
    installments?: number;
    shortId?: string;
    finishedMoment?: Date;
}

export interface ICheckoutState {
    currentUser: IPublicUser;
    personalAddress?: IUserAddress;
    deliveredAddress?: IUserAddress;
    deliveryAddressEqualResidence?: boolean;
    paymentType?: PaymentType | null;
    creditCard?: ICreditCard;
    amountMonth?: number;
    total?: number;
    hasDiscount?: boolean;
    hasReferral?: boolean;
    referralCode?: string;
    finishedCheckout?: IFinishedCheckout;
    currentTab: TabsCheckoutKey;
    couponPix?: IPublicCoupon;
    newAccount?: boolean;
};

const initialState = {
    deliveryAddressEqualResidence: true,
    creditCard: { installments: 1, errors: false },
    hasDiscount: false,
    hasReferral: false,
    referralCode: '',
    currentTab: TabsCheckoutKey.PERSONAL_DATA,
} as ICheckoutState;

export const checkoutReducer = (
    state: ICheckoutState = initialState,
    action: ICheckoutAction
): ICheckoutState => {
    switch (action.type) {
        case CheckoutActionTypes.SET_USER_DATA: {
            const { addresses } = action.userCurrent;
            const address = addresses[addresses ? addresses.length - 1 : 0];
            return {
                ...state,
                currentUser: action.userCurrent,
                personalAddress: address,
            };
        }
        case CheckoutActionTypes.SET_SAME_ADDRESSES: {
            return {
                ...state,
                deliveredAddress: action.value ? state.personalAddress : state.deliveredAddress,
                deliveryAddressEqualResidence: action.value
            }
        }
        case CheckoutActionTypes.SET_PERSONAL_DATA: {
            const { personalData, phone } = action;
            const user = { ...state.currentUser, personalData: { ...state.currentUser.personalData, ...personalData }, phone };
            return { ...state, currentUser: user };
        }
        case CheckoutActionTypes.SET_TYPE_PAYMENT: {
            const { value, total } = action;
            if (value === PaymentType.BOLETO || value === PaymentType.PIX) {
                return {
                    ...state,
                    creditCard: initialState.creditCard,
                    amountMonth: initialState.amountMonth,
                    total,
                    paymentType: value
                };
            }
            return { ...state, paymentType: value };
        }
        case CheckoutActionTypes.SET_CREDIT_CARD: {
            const { data } = action;
            return { ...state, creditCard: { ...state.creditCard, ...data } };
        }
        case CheckoutActionTypes.SET_INSTALLMENTS: {
            const { installments, total, amountMonth } = action;
            return {
                ...state,
                creditCard: { ...state.creditCard, installments },
                total,
                amountMonth
            }
        }
        case CheckoutActionTypes.SET_HAS_DISCOUNT: {
            const { hasDiscount } = action;
            return { ...state, hasDiscount }
        }
        case CheckoutActionTypes.SET_HAS_REFERRAL: {
            const { hasReferral } = action;
            return { ...state, hasReferral }
        }
        case CheckoutActionTypes.SET_REFERRAL_CODE: {
            const { referralCode } = action;
            return { ...state, referralCode }
        }
        case CheckoutActionTypes.SET_DELIVERED_ADDRESS: {
            const { address } = action;
            return { ...state, deliveredAddress: address };
        }
        case CheckoutActionTypes.SET_ADDRESS_PERSONAL: {
            const { address } = action;
            return { ...state, personalAddress: address };
        }
        case CheckoutActionTypes.SET_FINISHED_PRODUCT: {
            const { product, total, linkPayment, installments, shortId, finishedMoment } = action;
            return { ...state, finishedCheckout: { product, total, linkPayment, blockCheckout: false, installments, shortId, finishedMoment } };
        }
        case CheckoutActionTypes.SET_BLOCK_CHECKOUT: {
            return { ...state, finishedCheckout: { ...state.finishedCheckout, blockCheckout: true } };
        }
        case CheckoutActionTypes.CLEAN_CHECKOUT: {
            const nextState: ICheckoutState = { ...initialState, newAccount: state.newAccount };
            return nextState;
        }
        case CheckoutActionTypes.CLEAN_CREDIT_CARD: {
            return { ...state, creditCard: initialState.creditCard, paymentType: action.cleanTypePayment ? null : state.paymentType };
        }
        case CheckoutActionTypes.SET_CURRENT_TAB: {
            const { newTab } = action;
            return { ...state, currentTab: newTab };
        }
        case CheckoutActionTypes.SET_COUPON_PIX_DEFAULT: {
            const { coupon } = action;
            return { ...state, couponPix: coupon };
        }
        case CheckoutActionTypes.SET_TOKEN_CREDIT_CARD: {
            const { cardInfo } = action;
            return { ...state, creditCard: { ...state.creditCard, cardInfo: { hash: cardInfo.token, issuerId: cardInfo.issuerId, paymentMethodId: cardInfo.paymentMethodId } } }
        }
        case CheckoutActionTypes.SET_NEW_ACCOUNT: {
            const { newAccount } = action;
            return { ...state, newAccount };
        }
        default:
            return state;
    }
};

/**
 * Actions
 */

export const SET_USER_DATA = (userCurrent: IPublicUser): ICheckoutAction => ({
    type: CheckoutActionTypes.SET_USER_DATA,
    userCurrent
});

export const SET_PERSONAL_DATA = (personalData: IPersonalData, phone: string): ICheckoutAction => ({
    type: CheckoutActionTypes.SET_PERSONAL_DATA,
    personalData,
    phone
});

export const SET_SAME_ADDRESSES = (value: boolean): ICheckoutAction => ({
    type: CheckoutActionTypes.SET_SAME_ADDRESSES,
    value
});

export const SET_TYPE_PAYMENT = (value: PaymentType, total: number): ICheckoutAction => ({
    type: CheckoutActionTypes.SET_TYPE_PAYMENT,
    value,
    total
});

export const SET_CREDIT_CARD = (data: IDynamicKeyCheckout): ICheckoutAction => ({
    type: CheckoutActionTypes.SET_CREDIT_CARD,
    data,
});

export const CLEAN_CREDIT_CARD = (cleanTypePayment?: boolean): ICheckoutAction => ({
    type: CheckoutActionTypes.CLEAN_CREDIT_CARD,
    cleanTypePayment,
});

export const SET_INSTALLMENTS = (total: number, installments: number, amountMonth: number): ICheckoutAction => ({
    type: CheckoutActionTypes.SET_INSTALLMENTS,
    total,
    amountMonth,
    installments,
});

export const SET_HAS_DISCOUNT = (hasDiscount: boolean): ICheckoutAction => ({
    type: CheckoutActionTypes.SET_HAS_DISCOUNT,
    hasDiscount,
});

export const SET_HAS_REFERRAL = (hasReferral: boolean): ICheckoutAction => ({
    type: CheckoutActionTypes.SET_HAS_REFERRAL,
    hasReferral,
});

export const SET_REFERRAL_CODE = (referralCode: string): ICheckoutAction => ({
    type: CheckoutActionTypes.SET_REFERRAL_CODE,
    referralCode,
});

export const SET_DELIVERED_ADDRESS = (address: IUserAddress): ICheckoutAction => ({
    type: CheckoutActionTypes.SET_DELIVERED_ADDRESS,
    address
});

export const SET_ADDRESS_PERSONAL = (address: IUserAddress): ICheckoutAction => ({
    type: CheckoutActionTypes.SET_ADDRESS_PERSONAL,
    address
});

export const SET_FINISHED_PRODUCT = ({ product, total, linkPayment, installments, shortId, finishedMoment }: IFinishedCheckout): ICheckoutAction => ({
    type: CheckoutActionTypes.SET_FINISHED_PRODUCT,
    product,
    total,
    linkPayment,
    installments,
    shortId,
    finishedMoment
});

export const CLEAN_CHECKOUT = (): ICheckoutAction => ({
    type: CheckoutActionTypes.CLEAN_CHECKOUT
});


export const SET_BLOCK_CHECKOUT = (): ICheckoutAction => ({
    type: CheckoutActionTypes.SET_BLOCK_CHECKOUT
});

export const SET_CURRENT_TAB = (newTab: TabsCheckoutKey): ICheckoutAction => ({
    type: CheckoutActionTypes.SET_CURRENT_TAB,
    newTab
});

export const SET_COUPON_PIX_DEFAULT = (coupon: IPublicCoupon): ICheckoutAction => ({
    type: CheckoutActionTypes.SET_COUPON_PIX_DEFAULT,
    coupon
});

export const SET_TOKEN_CREDIT_CARD = (cardInfo): ICheckoutAction => ({
    type: CheckoutActionTypes.SET_TOKEN_CREDIT_CARD,
    cardInfo
});
export const SET_NEW_ACCOUNT = (newAccount: boolean): ICheckoutAction => ({
    type: CheckoutActionTypes.SET_NEW_ACCOUNT,
    newAccount
})
