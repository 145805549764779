import React from "react";

// import logo from "../../../../assets/img/FastCheckout/mercado-pago-cartões.png"

import { Box, Typography } from "@mui/material";
import moment from 'moment';

function Footer() {
    return (
        <Box display="flex" gap={3} flexDirection="column">
            <Box>
                <Typography color="#757575" fontFamily="Chillax" mb={1} fontSize={12}>@{moment().year()} - Allugator - Assinatura de Eletrônicos.</Typography>
                <Typography color="#757575" fontFamily="Chillax" fontSize={12}>CNPJ: 25.053.921/0001-43</Typography>
            </Box>
        </Box>
    )
}

export default Footer
