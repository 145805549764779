import { Box, Grid, Typography } from "@mui/material";
import { GridRowParams } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { withApollo, WithApolloClient } from "react-apollo";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { ActionCreator, AnyAction, Dispatch } from "redux";
import { ICreditCard } from "../../actionReducers/Checkout";
import { IGeneralAction, SET_LOADING } from "../../actionReducers/General";
import Header from "../../components/Pages/Checkout/Header";
import ChargesTable from "../../components/Pages/PaymentsRecurrence/ChargesTable";
import NextPayments from "../../components/Pages/PaymentsRecurrence/NextPayments";
import PaymentFlowModal, {
  ModalType
} from "../../components/Pages/PaymentsRecurrence/PaymentFlowModal";
import PersonalData from "../../components/Pages/PaymentsRecurrence/PersonalData";
import PaymentDetail from "../../components/PaymentDetail";
import ProductDetail from "../../components/ProductDetail";
import {
  IMutationCreateOrderRecurrence,
  MUTATION_CREATE_ORDER_RECURRENCE
} from "../../graphql/mutations/checkoutOrders";
import {
  IQueryAllPaymentsByOrderId,
  IQueryDetachedPaymentsByOrderId,
  QUERY_ALL_PAYMENTS_ORDER,
  QUERY_DETACHED_PAYMENTS_ORDER
} from "../../graphql/queries/checkoutOrders";
import {
  ICheckoutOrder,
  IExpectedPayments,
  IUserDataHidden,
  PagarmeLog,
  State
} from "../../graphql/types/checkoutOrders";
import { PaymentStatusTypes } from "../../graphql/types/general";
import toReal from "../../helpers/toReal";
import { IAppState } from "../../store";
import { TextDescription } from "./styled";
import DetachedPayments from "../../components/Pages/PaymentsRecurrence/DetachedPayments";
import { generateTokenCard } from "../../services/card/generateToken";
interface IProps {
  loading: boolean;
  setLoading: ActionCreator<IGeneralAction>;
}

const PaymentsRecurrence = ({
  client,
  setLoading,
  location,
  loading,
  history
}: IProps & WithApolloClient<{}> & RouteComponentProps<{}>) => {
  const [user, setUser] = useState<IUserDataHidden>();
  const [order, setOrder] = useState<ICheckoutOrder>();
  const [payments, setPayments] = useState<IExpectedPayments[]>();
  const [modal, setModal] = useState<boolean>(false);
  const [typeModal, setTypeModal] = useState<ModalType>(ModalType.PAYMENT);
  const [currentCardPayment, setCurrentCardPayment] = useState<
    IExpectedPayments
  >();
  const [detachedPayments, setDetachedPayments] = useState<PagarmeLog[]>();

  const handleCloseModal = () => {
    setTypeModal(ModalType.PAYMENT);
    setModal(false);
  };

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const id = query.get("pedido");
    searchOrder(id);
  }, []);

  async function searchOrder(id: string) {
    try {
      setLoading(true);
      const {
        data: {
          allPaymentsByOrderId: { order, payments, user }
        }
      } = await client.query<IQueryAllPaymentsByOrderId>({
        query: QUERY_ALL_PAYMENTS_ORDER,
        variables: { id },
        fetchPolicy: "no-cache"
      });

      const {
        data: {
          detachedPaymentsByOrderId: { detachedPayment }
        }
      } = await client.query<IQueryDetachedPaymentsByOrderId>({
        query: QUERY_DETACHED_PAYMENTS_ORDER,
        variables: { id },
        fetchPolicy: "no-cache"
      });

      if (
        !order.checkoutOrderProducts ||
        order.checkoutOrderProducts[0].state !== State.pedidoEntregue
      ) {
        toast.error(
          "Funcionalidade disponível apenas para pedidos já entregues."
        );
        setLoading(false);
        history.push("/");
        return;
      }

      setDetachedPayments(detachedPayment)
      setOrder(order);
      setPayments(payments);
      setUser(user);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error("Erro ao carregar o pedido, por favor verifique seus dados.");
      history.push("/");
      return;
    }
  }

  const formatCard = (values: ICreditCard) => {
    const { number, holderName, expirationDate, cvv } = values;
    const card = {
      number: number.replace(/\s/g, ""),
      holder_name: holderName,
      exp_month: expirationDate.substring(0, 2),
      exp_year: expirationDate.substring(2),
      cvv: cvv
    };
    return card;
  };

  const createOrder = async (
    values: ICreditCard,
    changeCardAllCharges?: boolean
  ) => {
    try {
      setLoading(true);
      const card = formatCard(values);
      const token = await generateTokenCard(card);

      if (!token) {
        toast.error("Cartão inválido, por favor verifique seus dados.");
        return;
      }

      const tokenChangeCard =
        (changeCardAllCharges && order && order.subscriptionPayment) ||
          !order.subscriptionPayment.byTotodile
          ? await generateTokenCard(card)
          : null;

      const {
        data: { createOrderExternal }
      } = await client.mutate<IMutationCreateOrderRecurrence>({
        mutation: MUTATION_CREATE_ORDER_RECURRENCE,
        variables: {
          orderId: order._id,
          tokenCard: token,
          changeCardAllCharges,
          tokenChangeCard
        }
      });
      setLoading(false);
      if (createOrderExternal) {
        searchOrder(order._id);
        setTypeModal(ModalType.FEEDBACK);
      }
    } catch (err) {
      toast.error("Erro ao criar pedido.");
      setLoading(false);
    }
  };

  useEffect(() => {
    if (payments) {
      handleCurrentCardPayment();
    }
  }, [payments]);

  const handleCurrentCardPayment = () => {
    if (payments) {
      const latePayment = payments.find(
        item => item.expectedPayment && !item.payment
      );
      if (latePayment) {
        setCurrentCardPayment(latePayment);
      } else {
        const paymentPaid = payments.find(
          item => item.expectedPayment && item.start && item.end
        );
        setCurrentCardPayment(paymentPaid);
      }
    }
  };

  const handleChosenPayment = (data: GridRowParams) => {
    const paymentChosen = payments.find(item => item.index === data.id);
    setCurrentCardPayment(paymentChosen);
  };

  const handleDefineStatus = (item: IExpectedPayments) => {
    if (
      item.expectedPayment &&
      item.payment &&
      item.payment.data &&
      item.payment.data.result
    ) {
      if (item.payment.data.result.status === "paid")
        return PaymentStatusTypes.PAID;
      return PaymentStatusTypes.PROCESSING;
    } else {
      return PaymentStatusTypes.UNPAID;
    }
  };

  return (
    <React.Fragment>
      <Header />
      <Grid
        container
        maxWidth={1440}
        margin={"0px auto"}
        sx={{
          padding: {
            xs: "20px 20px 23px 20px",
            sm: "20px 84px 46px 84px",
            md: "40px 144px 92px 144px"
          }
        }}
      >
        {currentCardPayment && order.subscriptionPayment ? (
          <PaymentFlowModal
            currentCardPayment={currentCardPayment}
            open={modal}
            handleClose={handleCloseModal}
            amountMonth={order.subscriptionPayment.monthValue}
            submit={createOrder}
            modalType={typeModal}
            loading={loading}
            oldRecurrence={order.subscriptionPayment.byTotodile ? false : true}
          />
        ) : null}
        {order ? (
          <>
            <Box sx={{ marginBottom: "28px" }}>
              <Typography variant="h5">Pedido {order.shortId}</Typography>
            </Box>
            <Grid
              container
              columnSpacing={{ md: "24px", xs: "0px" }}
              sx={{
                boxShadow: {
                  xs: "0px 5px 8px rgba(120, 111, 111, 0.15)",
                  md: "0"
                },
                marginBottom: "48px"
              }}
            >
              <Grid item xs={12} sm={6}>
                {user ? <PersonalData {...user} /> : null}
              </Grid>
              <Grid item xs={12} sm={6}>
                {order.checkoutOrderProducts &&
                  order.checkoutOrderProducts.length ? (
                  <ProductDetail
                    product={order.checkoutOrderProducts[0].product}
                  >
                    <Typography variant="subtitle2" fontSize={"15px"}>
                      Mensalidade:
                      <TextDescription>
                        &nbsp;12 parcelas de&nbsp;
                        {toReal(order.subscriptionPayment.monthValue)}
                      </TextDescription>
                    </Typography>
                  </ProductDetail>
                ) : null}
              </Grid>
            </Grid>
            <Typography
              variant="h6"
              fontSize="21px"
              sx={{ paddingBottom: "16px" }}
            >
              Faturas
            </Typography>
            <Grid
              container
              rowSpacing={{ md: "0px", xs: "36px" }}
              columnSpacing={{ md: "24px", xs: "0px" }}
            >
              <Grid
                item
                md={8}
                xs={12}
                sx={{ height: { xs: "344px", md: "none" } }}
              >
                {payments ? (
                  <ChargesTable
                    payments={payments}
                    handleChosenPayment={handleChosenPayment}
                    handleDefineStatus={handleDefineStatus}
                    amountMonth={order.subscriptionPayment.monthValue}
                  />
                ) : null}
              </Grid>
              <Grid item md={4} xs={12}>
                <Box>
                  {currentCardPayment ? (
                    <PaymentDetail
                      currentCardPayment={currentCardPayment}
                      type={handleDefineStatus(currentCardPayment)}
                      amountMonth={order.subscriptionPayment.monthValue}
                      openModalPayment={() => setModal(true)}
                    />
                  ) : null}
                </Box>
              </Grid>
              <Grid
                container
                sx={{
                  marginTop: "48px",
                  paddingLeft: { md: "24px", xs: "0px" }
                }}
              >
                <Typography
                  variant="h6"
                  fontSize="21px"
                  sx={{ paddingBottom: "16px" }}
                >
                  Próximos pagamentos
                </Typography>
                <Grid item xs={12}>
                  {payments ? (
                    <NextPayments
                      payments={payments}
                      amountMonth={order.subscriptionPayment.monthValue}
                    />
                  ) : null}
                </Grid>
              </Grid>
              <Grid
                container
                sx={{
                  marginTop: "48px",
                  paddingLeft: { md: "24px", xs: "0px" }
                }}
              >
                {detachedPayments.length !== 0 ? (
                  <Typography
                    variant="h6"
                    fontSize="21px"
                    sx={{ paddingBottom: "16px" }}
                  >
                    Outros pagamentos
                  </Typography>
                ) : null}
                <Grid item xs={12}>
                  {detachedPayments.length !== 0 ? (
                    <DetachedPayments
                      detachedPayments={detachedPayments}
                    />
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          </>
        ) : null}
      </Grid>
    </React.Fragment>
  );
};

export default connect(
  (state: IAppState) => ({
    loading: state.general.loading
  }),
  (dispatch: Dispatch<AnyAction>) => ({
    setLoading: (value: boolean) => dispatch(SET_LOADING(value))
  })
)(withApollo(withRouter(PaymentsRecurrence)));
