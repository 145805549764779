import React from 'react';
import { Box, Grid, Typography, Stack, Chip, Accordion, AccordionDetails, AccordionSummary } from "@mui/material"
import { ExpandMore } from '@mui/icons-material'
import { connect } from 'react-redux';
import { IAppState } from '../../../../store';
import useMediaQuery from '@mui/material/useMediaQuery';
import toReal from '../../../../helpers/toReal';
import Footer from '../Footer';
import { PaymentMode } from '../../../../actionReducers/Checkout';

interface ProductPrice {
  hasDiscount: boolean
  total: number
  subTotal: number
}

enum PaymentModeDuration {
  'yearly' = 12,
  'semester' = 6,
  'quarterly' = 3
}

const ProductPrice = ({ hasDiscount, total, subTotal }: ProductPrice) => {
  return (
    <>
      {hasDiscount ?
        <Stack>
          <Typography color="#C1C1BD" fontWeight={500} fontFamily='Chillax'>De: {toReal(subTotal)}</Typography>
          <Typography color="#007D34" fontWeight={500} fontFamily='Chillax'>Por: <Typography variant='h5' component='span' fontWeight={500}>{toReal(total)}</Typography></Typography>
        </Stack>
        : <Typography variant='h5' fontWeight={500} fontFamily='Chillax'>{toReal(subTotal)}</Typography>
      }
    </>
  )
}

const isValidPaymentMode = (paymentMode: PaymentMode) => {
  const validPaymentModes = [PaymentMode.YEARLY, PaymentMode.SEMESTER, PaymentMode.QUARTERLY, PaymentMode.RECURRENCE];
  return paymentMode && validPaymentModes.includes(paymentMode);
}

const IncludedItems = () => {
  return (
    <Stack spacing={1}>
      <Box display="flex" justifyContent="space-between">
        <Typography color="#757575">allu.care</Typography>
        <Typography color="primary" fontWeight={500}>grátis</Typography>
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Typography color="#757575">allu.shield</Typography>
        <Typography color="primary" fontWeight={500}>grátis</Typography>
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Typography color="#757575">carregador</Typography>
        <Typography color="primary" fontWeight={500}>grátis</Typography>

      </Box>
      <Box display="flex" justifyContent="space-between">
        <Typography color="#757575">capinha</Typography>
        <Typography color="primary" fontWeight={500}>grátis</Typography>

      </Box>
      <Box display="flex" justifyContent="space-between">
        <Typography color="#757575">película</Typography>
        <Typography color="primary" fontWeight={500}>grátis</Typography>
      </Box>
    </Stack>
  )
}

const ProductShowcase = (props: any) => {
  const splitedProductName = props.product?.name.split(' ')

  const isSmartphone = props.product?.category?.name === 'Smartphones'

  const productCapacity = isSmartphone ? props.productSlug?.pop() : ''

  const productName = splitedProductName?.join(' ') ?? ''

  const renderAccordion = useMediaQuery('(max-width:500px)');

  const renderFooter = useMediaQuery('(min-width:1000px)');

  return (
    <Box>
      <Grid container columns={2} maxWidth={420} gap={3}>
        <Grid item xs>
          <Box maxWidth={190} display="flex" justifyContent="center" alignItems="center">
            <img src={props.product?.photos[0] ?? ''} style={{ display: 'block', height: '100%', width: '100%' }} />
          </Box>
        </Grid>
        <Grid item>
          <Stack maxWidth={230} spacing={2}>
            <Box>
              <Typography variant='h5' fontWeight={500} fontFamily='Chillax'>{productName}</Typography>
              <Typography color="text.secondary" fontWeight={500} fontFamily='Chillax'>{productCapacity}</Typography>
              <Typography color="#007D34" fontWeight={500} fontFamily='Chillax'>{`Prazo entrega: ${props.product?.deadline} dias úteis`}</Typography>
              {props.loyaltyPlan && props.loyaltyPlan !== 'none' && <Typography color="#007D34" fontWeight={500} fontFamily='Chillax'>{`Fidelidade: ${props.loyaltyPlan} meses`}</Typography>}
              {props.paymentMode === 'recurrence' ? (
                <Typography color="#000000" fontWeight={500} fontFamily='Chillax'>Assinatura mensal</Typography>
              ) :
                <Typography color="#007D34" fontWeight={500} fontFamily='Chillax'>{`Assinatura de ${isValidPaymentMode(props.paymentMode) ? PaymentModeDuration[props.paymentMode] : 12} meses`}</Typography>
              }
            </Box>
            <Box>
              {props.paymentMode === 'recurrence' ?
                <Typography color='#007D34' variant='h5' fontWeight={500} fontFamily='Chillax'>{`${toReal(props.recurrenceValue)}/mês`}</Typography>
                :
                <ProductPrice
                  hasDiscount={props.hasDiscount}
                  subTotal={props.subTotal}
                  total={props.total}
                />
              }
              {props.liveloOrigin && (
                <Typography fontFamily='Chillax'>
                  {props.clubScore ? `Pontos Padrão: ${props.score}` : `Ganhe ${props.score} pontos Livelo`}
                </Typography>
              )}
              {props.liveloOrigin && props.clubScore && (
                <Typography fontFamily='Chillax'>
                  {`Pontos Clube LIVELO: ${props.clubScore}`}
                </Typography>
              )}
            </Box>
            <Chip label="Frete grátis" sx={{ background: '#2E7D32', color: '#fff', width: 95 }} size="small" />
            {renderAccordion ?
              isSmartphone && <Accordion style={{ boxShadow: 'none' }}>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography fontWeight={500} mb={1}>Itens inclusos</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <IncludedItems />
                </AccordionDetails>
              </Accordion>
              :
              isSmartphone && <Box>
                <Typography fontWeight={500} mb={1}>Itens inclusos</Typography>
                <IncludedItems />
              </Box>}
          </Stack>
        </Grid>
      </Grid>
      {renderFooter && <Box mt={6}>
        <Footer />
      </Box>}
    </Box>
  )
}

export default connect((state: IAppState) => ({
  total: state.cart.total,
  subTotal: state.cart.subtotal,
  items: state.cart.items,
  paymentType: state.fastCheckout.paymentType,
  hasDiscount: state.fastCheckout.hasDiscount,
  currentTab: state.fastCheckout.currentTab,
  creditCard: state.fastCheckout.creditCard,
  amountMonth: state.fastCheckout.amountMonth,
}))(ProductShowcase);