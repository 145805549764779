import { Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ActionCreator, Dispatch } from "redux";
import {
  PaymentMode,
  PaymentType,
} from "../../../actionReducers/Checkout";
import AddressInformationFastCheckout from "../../../components/Pages/Checkout/AdressInformationFastCheckout";
import PaymentFastPix from "../../../components/Pages/Checkout/EndCheckout/paymentFastPix";
import GeneralInformation from "../../../components/Pages/Checkout/GeneralInformation";
import ModalSatisfactorySurvey from "../../../components/SatisfactorSurvey";
import { ModalWrapper } from "../../../components/styled";
import { IUserAddress } from "../../../graphql/types/users";
import { IAppState } from "../../../store";
import Footer from "../../HomeV2/components/Footer";
import NewNavbar from "../../HomeV2/components/NewNavbar";
import InformationFastOrder from "../../../components/Pages/Checkout/EndCheckout/InformationFastOrdem";
import { CLEAN_CREDIT_CARD_FAST_CHECKOUT, IFastCheckoutAction, IPersonalDataFastCheckout, SET_BLOCK_CHECKOUT_FAST_CHECKOUT, SET_CAMPAIGN_FAST_CHECKOUT } from "../../../actionReducers/FastCheckout";
import InformationNewAccount from "../../../components/Pages/Checkout/EndCheckout/InformationNewAccount";
import { IProduct } from "../../../graphql/types/products";
import PaymentPicpay from "../../../components/Pages/Checkout/EndCheckout/paymentPicpay";

interface IProps extends RouteComponentProps<{}> {
  personalData: IPersonalDataFastCheckout;
  paymentType: PaymentType;
  paymentMode: PaymentMode;
  campaign?: string;
  newAccount: boolean;
  deliveredAddress: IUserAddress;
  product: IProduct;
  setBlockCheckout: ActionCreator<IFastCheckoutAction>;
  cleanCreditCard: ActionCreator<IFastCheckoutAction>;
  setCampaign: ActionCreator<IFastCheckoutAction>;
}

const EndCheckout = ({
  personalData,
  paymentType,
  deliveredAddress,
  newAccount,
  product,
  history,
  campaign,
  paymentMode,
  setBlockCheckout,
  cleanCreditCard,
}: IProps) => {
  const { email, phone, firstName, lastName } = personalData || {};
  const [displaySurvey, setDisplaySurvey] = useState<boolean>(true);

  useEffect(() => {
    setBlockCheckout();
    cleanCreditCard();
  }, []);

  useEffect(() => {
    if (!personalData) {
      history.push("/");
    }
  }, [personalData]);

  const closeModal = () => {
    setDisplaySurvey(false);
  };

  useEffect(() => {
    showSurvey();
  }, [paymentType]);

  const showSurvey = async () => {
    if (
      paymentType === PaymentType.CREDIT_CARD &&
      email &&
      phone &&
      personalData
    ) {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.async = true;
      script.innerHTML = `
      (function (f, b, g) {
        var a = {
          key: 'GEqMVah9',
          appendTo: 'survey',
          customer: {
            name: '${firstName} ${lastName}',
            email: '${email}',
            phone: '${phone}'
          }      
        };
        var e;
        var c = f.getElementsByTagName(b)[0];
        if (f.getElementById(g)) { return };
        e = f.createElement(b);
        e.id = g;
        e.src = 'https://app.track.co/widget.min.js';
        e.type = 'text/javascript';
        e.async = true;
        e.onload = e.onreadystatechange = function () {
          var h = this.readyState;
        if (h && h !== 'complete' && h !== 'loaded') { return };
        try {
          var c = new TrackWidget();
          c.createWidget(a);
        } catch (i) { }
      };
      c.parentNode.insertBefore(e, c);
    }(document, 'script', 'trackwidget-js'))
    `;
      document.head.appendChild(script);
    }
  };

  const showPaymentInformation = () => {
    switch (paymentType) {
      case PaymentType.CREDIT_CARD:
        return (
          <Stack
            spacing={"24px"}
          >
            {newAccount ? <InformationNewAccount /> : null}
            <Typography
              sx={{
                fontFamily: "Lato",
                fontSize: "17px",
                fontWeight: 600,
                color: "#000000",
                opacity: "87%"
              }}
            >
              Aguarde a confirmação do pagamento e acompanhe os próximos
              passos pelo seu e-mail ou em
              <span
                style={{ color: "#10D878", cursor: "pointer" }}
                onClick={() => history.push("/login?go=/pedidos/agendados")}
              >
                &nbsp;Meus Pedidos
              </span>
              .
            </Typography>
            <InformationFastOrder origin={campaign} paymentMode={paymentMode} />
          </Stack>
        )
      case PaymentType.PIX:
        return <PaymentFastPix />
      case PaymentType.PICPAY:
        return <PaymentPicpay />
    }
  }

  return (
    <>
      <NewNavbar />
      {paymentType && paymentType === PaymentType.CREDIT_CARD ? (
        <ModalSatisfactorySurvey closeFn={closeModal} display={displaySurvey}>
          <ModalWrapper>
            <div id="survey" />
          </ModalWrapper>
        </ModalSatisfactorySurvey>
      ) : null}
      {personalData ? (
        <>
          <Grid
            container
            maxWidth={1440}
            margin={"0px auto"}
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",

              marginTop: {
                xs: "70px",
                sm: "70px",
                md: "60px"
              },
              padding: {
                xs: "0px 18px 18px 18px",
                sm: "0px 36px 18px 36px",
                md: "0px 72px 18px 72px"
              }
            }}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Stack spacing={"24px"} paddingBottom={"66px"}>
                <Typography fontFamily={"Lato"} fontSize={36} fontWeight={400}>
                  Seu pedido foi emitido!
                </Typography>
                {showPaymentInformation()}
                {deliveredAddress && <AddressInformationFastCheckout {...deliveredAddress} />}
                <GeneralInformation {...product} />
              </Stack>
            </Grid>
          </Grid>
        </>
      ) : null}
      <Footer />
    </>
  );
};

export default withRouter(
  connect(
    (state: IAppState) => ({
      personalData: state.fastCheckout.personalData,
      paymentType: state.fastCheckout.paymentType,
      newAccount: state.fastCheckout.newAccount,
      deliveredAddress: state.fastCheckout.deliveredAddress,
      product: state.fastCheckout.finishedCheckout.product,
      campaign: state.fastCheckout.campaign,
      paymentMode: state.fastCheckout.paymentMode,
    }),
    (dispatch: Dispatch<IFastCheckoutAction>) => ({
      setBlockCheckout: () => dispatch(SET_BLOCK_CHECKOUT_FAST_CHECKOUT()),
      cleanCreditCard: () => dispatch(CLEAN_CREDIT_CARD_FAST_CHECKOUT()),
      setCampaign: (campaign: string | null) => dispatch(SET_CAMPAIGN_FAST_CHECKOUT(campaign)),
    })
  )(EndCheckout)
);
