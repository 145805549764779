export enum CartActionTypes {
  LOAD_DATA_FROM_LOCAL_STORAGE = "LOAD_DATA_FROM_LOCAL_STORAGE",
  ADD_TO_CART = "ADD_TO_CART",
  REMOVE_FROM_CART = "REMOVE_FROM_CART",
  REMOVE_COUPON_FROM_CART = "REMOVE_COUPON_FROM_CART",
  CLEAR_CART = "CLEAR_CART",
  SELECT_ADDRESS = "SELECT_ADDRESS",
  REMOVE_SELECTED_ADDRESS = "REMOVE_SELECTED_ADDRESS",
  UPDATED_CARD = "UPDATED_CARD",
  ADD_COUPON = "ADD_COUPON",
  ADD_GOPHONE = "ADD_GOPHONE",
  REMOVE_GOPHONE = "REMOVE_GOPHONE",
  SET_TOTAL = "SET_TOTAL",
  CHANGE_SUBSCRIPTION_PLAN = "CHANGE_SUBSCRIPTION_PLAN"
}

export enum GeneralActionTypes {
  SHOW_CATEGORIES_STATE = "SHOW_CATEGORIES_STATE",
  SET_LOADING = "SET_LOADING",
  SET_ORDER_CONFIRMATION = "SET_ORDER_CONFIRMATION",
  SET_MISSING_PAYMENT = "SET_MISSING_PAYMENT",
  SET_MISSING_ORDER_ID = "SET_MISSING_ORDER_ID",
  SET_TRANSACTION_ID = "SET_TRANSACTION_ID",
  SET_TYPE_PAYMENT = "SET_TYPE_PAYMENT",
  LOAD_TYPE_PAYMENT_FROM_LOCAL_STORAGE = "LOAD_TYPE_PAYMENT_FROM_LOCAL_STORAGE"
}

export enum SearchActionTypes {
  SET_TERM = "SET_TERM"
}

export enum UserActionTypes {
  SET_LOGGED_STATE = "SET_LOGGED_STATE",
  SET_HAS_NORMAL_ORDER = "SET_HAS_NORMAL_ORDER"
}

export enum SidebarActionTypes {
  TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR"
}

export enum ProductActionTypes {
  SET_PRODUCTS = "SET_PRODUCTS",
  SET_PRE_RELEASE = "SET_PRE_RELEASE",
  SET_SHOW_PRE_OWNED_PRODUCTS = "SET_SHOW_PRE_OWNED_PRODUCTS",
  SET_PROMOTIONAL_PERIOD = "SET_PROMOTIONAL_PERIOD",
  SET_PRE_OWNED_VERSION_CHOSEN = "SET_PRE_OWNED_VERSION_CHOSEN",
}

export enum ProfileActionTypes {
  SET_CREDIT_CARD_PROFILE = "SET_CREDIT_CARD_PROFILE",
  SET_CREDIT_CARD_TOKEN_PROFILE = "SET_CREDIT_CARD_TOKEN_PROFILE",
  CLEAN_PROFILE = "CLEAN_PROFILE",
  CLEAN_CREDIT_CARD_PROFILE = "CLEAN_CREDIT_CARD_PROFILE",
  CLEAN_CREDIT_CARD_TOKEN_PROFILE = "CLEAN_CREDIT_CARD_TOKEN_PROFILE",
}

export enum CheckoutActionTypes {
  SET_PERSONAL_DATA = "SET_PERSONAL_DATA",
  SET_USER_DATA = "SET_USER_DATA",
  SET_SAME_ADDRESSES = "SET_SAME_ADDRESSES",
  SET_ADDRESS_PERSONAL = "SET_ADDRESS_PERSONAL",
  SET_TYPE_PAYMENT = "SET_TYPE_PAYMENT",
  SET_CREDIT_CARD = "SET_CREDIT_CARD",
  SET_INSTALLMENTS = "SET_INSTALLMENTS",
  SET_CLEAN_CREDIT_CARD = "SET_CLEAN_CREDIT_CARD",
  SET_HAS_DISCOUNT = "SET_HAS_DISCOUNT",
  SET_DELIVERED_ADDRESS = "SET_DELIVERED_ADDRESS",
  SET_FINISHED_PRODUCT = "SET_FINISHED_PRODUCT",
  CLEAN_CHECKOUT = "CLEAN_CHECKOUT",
  SET_BLOCK_CHECKOUT = "SET_BLOCK_CHECKOUT",
  CLEAN_CREDIT_CARD = "CLEAN_CREDIT_CARD",
  SET_HAS_REFERRAL = "SET_HAS_REFERRAL",
  SET_REFERRAL_CODE = "SET_REFERRAL_CODE",
  SET_CURRENT_TAB = "SET_CURRENT_TAB",
  SET_COUPON_PIX_DEFAULT = "SET_COUPON_PIX_DEFAULT",
  SET_TOKEN_CREDIT_CARD = "SET_TOKEN_CREDIT_CARD",
  SET_NEW_ACCOUNT = "SET_NEW_ACCOUNT",
}

export enum CheckoutRecurrenceActionTypes {
  SET_TYPE_PAYMENT_ENTRY = "SET_TYPE_PAYMENT_ENTRY",
  SET_TYPE_SINGLE_CARD = "SET_TYPE_SINGLE_CARD",
  SET_CARD_ENTRY = "SET_CARD_ENTRY",
  SET_CARD_MONTHLY = "SET_CARD_MONTHLY",
  SET_AMOUNT_ENTRY = "SET_AMOUNT_ENTRY",
  SET_AMOUNT_MONTHLY = "SET_AMOUNT_MONTHLY",
  SET_ORDER = "SET_ORDER",
  SET_ADDRESS_ORDER = "SET_ADDRESS_ORDER",
  CLEAN_CHECKOUT_RECURRENCE = "CLEAN_CHECKOUT_RECURRENCE",
  SET_LINK_BOLETO = "SET_LINK_BOLETO"
}

export enum DocumentsActionTypes {
  SET_DIFFERENT_ADDRESS_ANSWER = "SET_DIFFERENT_ADDRESS_ANSWER",
  SET_IS_ADMIN = "SET_IS_ADMIN",
}

export enum FastCheckoutActionTypes {
  SET_USER_PERSONAL_DATA = "SET_USER_PERSONAL_DATA",
  SET_PERSONAL_DATA_ALREADY_SAVED = "SET_PERSONAL_DATA_ALREADY_SAVED",
  SET_CURRENT_TAB_FAST_CHECKOUT = "SET_CURRENT_TAB_FAST_CHECKOUT",
  SET_DELIVERED_ADDRESS_FAST_CHECKOUT = "SET_DELIVERED_ADDRESS_FAST_CHECKOUT",
  CLEAN_FAST_CHECKOUT = "CLEAN_FAST_CHECKOUT",
  SET_TYPE_PAYMENT_FAST_CHECKOUT = "SET_TYPE_PAYMENT_FAST_CHECKOUT",
  SET_INSTALLMENTS_FAST_CHECKOUT = "SET_INSTALLMENTS_FAST_CHECKOUT",
  SET_CREDIT_CARD_FAST_CHECKOUT = "SET_CREDIT_CARD_FAST_CHECKOUT",
  SET_HAS_DISCOUNT_FAST_CHECKOUT = "SET_HAS_DISCOUNT_FAST_CHECKOUT",
  SET_COUPON_PIX_DEFAULT_FAST_CHECKOUT = "SET_COUPON_PIX_DEFAULT_FAST_CHECKOUT",
  SET_TOKEN_CREDIT_CARD_FAST_CHECKOUT = "SET_TOKEN_CREDIT_CARD_FAST_CHECKOUT",
  SET_FINISHED_PRODUCT_FAST_CHECKOUT = "SET_FINISHED_PRODUCT_FAST_CHECKOUT",
  CLEAN_CREDIT_CARD_FAST_CHECKOUT = "CLEAN_CREDIT_CARD_FAST_CHECKOUT",
  SET_BLOCK_CHECKOUT_FAST_CHECKOUT = "SET_BLOCK_CHECKOUT_FAST_CHECKOUT",
  SET_CAMPAIGN_FAST_CHECKOUT = "SET_CAMPAIGN_FAST_CHECKOUT",
  SET_COUPON_FAST_CHECKOUT = "SET_COUPON_FAST_CHECKOUT",
  SET_ORIGIN_FAST_CHECKOUT = "SET_ORIGIN_FAST_CHECKOUT",
  SET_NEW_ACCOUNT_FAST_CHECKOUT = "SET_NEW_ACCOUNT_FAST_CHECKOUT",
  SET_RECURRENCE_VALUE_FAST_CHECKOUT = "SET_RECURRENCE_VALUE_FAST_CHECKOUT",
  SET_PAYMENT_MODE_FAST_CHECKOUT = "SET_PAYMENT_MODE_FAST_CHECKOUT",
}