import moment from 'moment';
import { ICartItem } from '../actionReducers/Cart';
import { IProduct } from '../graphql/types/products';
import allowedDays from '../config/allowedDays';

export enum LoyaltyPlan {
  twelve = '12',
  eighteen = '18',
  twentyOne = '21',
  twentyFour = '24',
  thirty = '30',
  thirtySix = '36'
}

export type IRates = {
  semesterRate: number,
  quarterlyRate: number,
  loyaltyRateTwelve?: number,
  loyaltyRateEighteen?: number,
  loyaltyRateTwentyOne?: number,
  loyaltyRateTwentyFour?: number,
  loyaltyRateThirty?: number,
  loyaltyRateThirtySix?: number,
  recurrenceRate?: number,
};

export const calculateFullPrice = ({ price, quantity, paymentMode, daysCompensatoryMeasure, rates, loyaltyPlan }: { price: number, quantity: number, paymentMode?: string, daysCompensatoryMeasure?: number, rates?: IRates, loyaltyPlan: LoyaltyPlan }) => {
  let daysToPay = 365;
  if (daysCompensatoryMeasure) {
    daysToPay = daysToPay - daysCompensatoryMeasure;
  }
  if (!price) {
    return 0;
  }

  const foundDate = allowedDays.find((day) => day.days === daysToPay);

  const bonus = 1 - ((foundDate ? foundDate.realDiscount : 0) / 100);
  let rawPrice = (Math.ceil(price * bonus * 100000) / 100000) * daysToPay * quantity;

  const annualValue = Math.round(rawPrice * 100) / 100;

  return handleRatesOnAnnualValue({ annualValue, paymentMode: paymentMode || 'yearly', rates, loyaltyPlan });
};

const handleLoyaltyPlanRate = ({ loyaltyPlan, rates }: { loyaltyPlan: LoyaltyPlan, rates?: IRates }) => {
  if (!rates) return 0;
  switch (loyaltyPlan) {
    case LoyaltyPlan.twelve:
      return rates.loyaltyRateTwelve || 0;
    case LoyaltyPlan.eighteen:
      return rates.loyaltyRateEighteen || 0;
    case LoyaltyPlan.twentyOne:
      return rates.loyaltyRateTwentyOne || 0;
    case LoyaltyPlan.twentyFour:
      return rates.loyaltyRateTwentyFour || 0;
    case LoyaltyPlan.thirty:
      return rates.loyaltyRateThirty || 0;
    case LoyaltyPlan.thirtySix:
      return rates.loyaltyRateThirtySix || 0;
    default:
      return 0;
  }
}

const handlePaymentModeRate = ({ paymentMode, rates }: { paymentMode: string, rates?: IRates }) => {
  if (!rates) return 0;
  switch (paymentMode) {
    case 'quarterly':
      return rates.quarterlyRate;
    case 'semester':
      return rates.semesterRate;
    case 'recurrence':
      return rates.recurrenceRate;
    default:
      return 0;
  }
}

export const handleRatesOnAnnualValue = ({ annualValue, paymentMode, loyaltyPlan, rates }: { annualValue: number, paymentMode: string, loyaltyPlan: LoyaltyPlan, rates?: IRates }) => {

  const paymentModeSpread = 1 + Number(handlePaymentModeRate({ paymentMode, rates }) || 0) / 100;
  const LoyaltyPlanSpread = 1 - Number(handleLoyaltyPlanRate({ loyaltyPlan, rates }) || 0) / 100;

  const annualizedValue = annualValue * paymentModeSpread * LoyaltyPlanSpread;

  if (paymentMode === 'quarterly') return annualizedValue / 4;
  if (paymentMode === 'semester') return annualizedValue / 2;

  return annualizedValue;
};

export const getPriceByPlan = (product: IProduct, plan: string, preowned?: boolean) => {
  if (!product) {
    return 0;
  }

  if (preowned) return product.prices.preowned || product.prices.signatureLite;

  switch (plan) {
    case 'keep':
      return product.prices.signatureKeep || product.prices.signatureLite;
    case 'switch':
      return product.prices.signatureSwitch || product.prices.signatureLite;
    default:
      return product.prices.signatureLite;
  }
};

function calculateSubtotal(items: ICartItem[], preowned: boolean = false, loyaltyPlan?: LoyaltyPlan) {
  return items.reduce((acc, i) => {
    i.startDate = moment(i.startDate);
    i.endDate = moment(i.endDate);

    const priceOnPlan = getPriceByPlan(i.product, i.plan, preowned)
    return acc + calculateFullPrice({
      price: priceOnPlan, quantity: i.quantity, daysCompensatoryMeasure: 0,
      rates: { semesterRate: i.product.semesterRate, quarterlyRate: i.product.quarterlyRate,
        loyaltyRateTwelve: i.product.loyaltyFees?.twelve, loyaltyRateEighteen: i.product.loyaltyFees?.eighteen,
        loyaltyRateTwentyOne: i.product.loyaltyFees?.twentyOne, loyaltyRateTwentyFour: i.product.loyaltyFees?.twentyFour,
        loyaltyRateThirty: i.product.loyaltyFees?.thirty, loyaltyRateThirtySix: i.product.loyaltyFees?.thirtySix
       },
      paymentMode: i.paymentMode, loyaltyPlan,
    });
  }, 0);
}

export default calculateSubtotal;
